import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useAuth } from 'src/common/context'
import * as S from './styles'
import { alertNotification, Container } from 'src/ui'
import { fetchOrderAnalytics, OrderAnalytics } from '../../common/api'
import AssetHoldingsOverview from './AssetHoldingsOverview'
import FireblocksHoldingsOverview from './FireblocksHoldingsOverview'

const AnalyticsDashboard: React.FC = () => {
  const { user } = useAuth()
  const [fetching, setFetching] = useState(true)
  const [orderAnalytics, setOrderAnalytics] = useState<OrderAnalytics[]>([])

  const getOrderAnalytics = async () => {
    try {
      const response = await fetchOrderAnalytics()
      setOrderAnalytics(response.data)
      setFetching(false)
    } catch (err) {
      alertNotification('Fetching withdrawals and deposits failed. Please contact support.', 'error')
      setFetching(false)
    }
  }

  useEffect(() => {
    getOrderAnalytics()
  }, [])

  return (
    <S.DashboardContainer notification={false}>
      <S.LeftSection>
        <S.SummaryContainer>
          {fetching && (
            <Box display="flex" justifyContent="center">
              <CircularProgress disableShrink />
            </Box>
          )}
          {!fetching && (
            <>
              <Container maxWidth="100%" paperPadding={30}>
                <FireblocksHoldingsOverview firstName={user?.given_name} />
              </Container>
              <Container maxWidth="100%" paperPadding={30}>
                <Typography variant="h5" fontWeight={500}>
                  Order Analytics
                </Typography>
                {orderAnalytics.map((data) => (
                  <AssetHoldingsOverview key={data.asset} orderAnalytics={data} />
                ))}
              </Container>
            </>
          )}
        </S.SummaryContainer>
      </S.LeftSection>
    </S.DashboardContainer>
  )
}

export default AnalyticsDashboard
