import * as React from 'react'
import { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid'
import InputBase from '@mui/material/InputBase'
import { alertNotification, Container } from '../../ui'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {
  adminGetMemberBlockchainDetails,
  BlockchainDetailsWithWhitelistInfoModel,
  unwhitelistMemberBlockchainDetails,
  whitelistMemberBlockchainDetails
} from '../../common/api/organizations'

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Blockchain Details ID', minWidth: 250 },
  { field: 'fireblocksId', headerName: 'Fireblocks ID', minWidth: 250 },
  { field: 'name', headerName: 'Name', minWidth: 250 },
  { field: 'chain', headerName: 'Chain', minWidth: 200 },
  { field: 'address', headerName: 'Address', minWidth: 250 },
  { field: 'riskScore', headerName: 'Risk Score', minWidth: 200 },
  { field: 'type', headerName: 'Type', minWidth: 250 },
  { field: 'createdAt', headerName: 'Created At', minWidth: 250 },
  { field: 'updatedAt', headerName: 'Updated At', minWidth: 250 },
  { field: 'contactId', headerName: 'Contact ID', minWidth: 250 },
  { field: 'memberId', headerName: 'Member ID', minWidth: 250 },
  { field: 'externalMemo', headerName: 'External Memo', minWidth: 250 }
]

export default function BlockchainDetails() {
  const [rows, setRows] = useState<GridRowModel[]>([])
  const [memberId, setMemberId] = useState<string>('')
  const [memberBlockchains, setMemberBlockchains] = useState<BlockchainDetailsWithWhitelistInfoModel[]>([])
  const [isWhitelistModalOpen, setIsWhitelistModalOpen] = useState<boolean>(false)
  const [isUnwhitelistModalOpen, setIsUnwhitelistModalOpen] = useState<boolean>(false)
  const [whitelistModalInput, setWhitelistModalInput] = useState<string>('')
  const [unwhitelistModalInput, setUnwhitelistModalInput] = useState<string>('')
  const [loading, setLoading] = React.useState(false)

  const whitelistBlockchain = async (data: { id: string; whitelisted: boolean; fireblocksId: string }) => {
    try {
      if (!data.fireblocksId && data.whitelisted) {
        await whitelistMemberBlockchainDetails(data.id)
        alertNotification(`Whitelisted ${data.id} successfully`, 'success')
      } else {
        await unwhitelistMemberBlockchainDetails(data.id)
        alertNotification(`Unwhitelisted ${data.id} successfully`, 'success')
      }
      await fetchMemberBlockchains()
      return data
    } catch (error) {
      alertNotification('Error updating row')
    }
  }

  const clearMemberBlockchainsState = () => {
    setMemberBlockchains([])
  }

  const fetchMemberBlockchains = async () => {
    try {
      setLoading(true)
      clearMemberBlockchainsState()
      const response = await adminGetMemberBlockchainDetails(memberId)
      if (response.data) {
        setMemberBlockchains(response.data)
        setLoading(false)
        alertNotification('Member blockchain details fetched successfully', 'success')
      }
    } catch (error) {
      alertNotification('Error fetching blockchain details')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (memberBlockchains.length > 0) {
      const newRows = memberBlockchains.map((item) => ({
        id: item.id,
        name: item.name,
        chain: item.chain,
        address: item.address,
        riskScore: item.riskScore,
        type: item.type,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        contactId: item?.contactId ? item.contactId : 'N/A',
        memberId: item.memberId ? item.memberId : 'N/A',
        fireblocksId: item.fireblocksId,
        externalMemo: item.externalMemo
      }))
      console.log('Updated rows:', newRows)
      setRows(newRows)
    }
  }, [memberBlockchains])

  const handleProcessRowUpdateError = (error: Error) => {
    alertNotification('Error updating row: ' + error.message)
  }

  const handleWhitelistModalOpen = () => {
    setIsWhitelistModalOpen(true)
  }

  const handleWhitelistModalClose = () => {
    setIsWhitelistModalOpen(false)
  }

  const handleWhitelistModalSubmit = async () => {
    try {
      await whitelistMemberBlockchainDetails(whitelistModalInput)
      alertNotification(`Whitelisted ${whitelistModalInput} successfully`, 'success')
      setWhitelistModalInput('')
      await fetchMemberBlockchains()
      handleWhitelistModalClose()
    } catch (error) {
      alertNotification('Error whitelisting blockchain details')
    }
  }

  const handleUnwhitelistModalOpen = () => {
    setIsUnwhitelistModalOpen(true)
  }

  const handleUnwhitelistModalClose = () => {
    setIsUnwhitelistModalOpen(false)
  }

  const handleUnwhitelistModalSubmit = async () => {
    try {
      await unwhitelistMemberBlockchainDetails(unwhitelistModalInput)
      alertNotification(`Unwhitelisted ${unwhitelistModalInput} successfully`, 'success')
      setUnwhitelistModalInput('')
      await fetchMemberBlockchains()
      handleUnwhitelistModalClose()
    } catch (error) {
      alertNotification('Error unwhitelisting blockchain details')
    }
  }

  return (
    <Container maxWidth={1500}>
      <Stack direction="column" spacing={8}>
        <Box>
          <Box mb={2}>
            <Typography variant="h5" gutterBottom>
              Members Blockchain Details
            </Typography>
            <Alert severity="info" sx={{ marginBottom: '15px' }}>
              {
                'Use this page to whitelist/unwhitelist blockchain details. Select whitelist or unwhitelist and enter blockchain details id. If there is a fireblock id, it has already been whitelisted.'
              }
            </Alert>
            <InputBase
              fullWidth
              placeholder="Enter Member ID"
              value={memberId}
              onChange={(e) => setMemberId(e.target.value)}
            />
            <Button variant="contained" onClick={fetchMemberBlockchains} sx={{ mt: 1 }}>
              Fetch Details
            </Button>
            <Button
              variant="contained"
              onClick={handleWhitelistModalOpen}
              sx={{ mt: 1, ml: 2, backgroundColor: 'green' }}
            >
              Whitelist
            </Button>
            <Button
              variant="contained"
              onClick={handleUnwhitelistModalOpen}
              sx={{ mt: 1, ml: 2, backgroundColor: 'red' }}
            >
              Unwhitelist
            </Button>
          </Box>
          <div style={{ height: 300, width: '100%' }}>
            <DataGrid
              loading={loading}
              autoPageSize
              rows={rows}
              columns={columns}
              processRowUpdate={(updatedRow) => whitelistBlockchain(updatedRow)}
              onProcessRowUpdateError={handleProcessRowUpdateError}
            />
          </div>
        </Box>
      </Stack>
      <Dialog open={isWhitelistModalOpen} onClose={handleWhitelistModalClose}>
        <DialogTitle>Whitelist Blockchain Details</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Blockchain Details ID"
            type="text"
            fullWidth
            value={whitelistModalInput}
            onChange={(e) => setWhitelistModalInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWhitelistModalClose}>Cancel</Button>
          <Button onClick={handleWhitelistModalSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isUnwhitelistModalOpen} onClose={handleUnwhitelistModalClose}>
        <DialogTitle>Unwhitelist Blockchain Details</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Blockchain Details Id"
            type="text"
            fullWidth
            value={unwhitelistModalInput}
            onChange={(e) => setUnwhitelistModalInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnwhitelistModalClose}>Cancel</Button>
          <Button onClick={handleUnwhitelistModalSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
