import { CreditScore } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ApiIcon from '@mui/icons-material/Api'
import AssessmentIcon from '@mui/icons-material/Assessment'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import DashboardIcon from '@mui/icons-material/Dashboard'
import FlagIcon from '@mui/icons-material/Flag'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import LogoutIcon from '@mui/icons-material/Logout'
import PeopleIcon from '@mui/icons-material/People'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import SendIcon from '@mui/icons-material/Send'
import { Box, Stack, Typography } from '@mui/material'
import React, { ReactNode, useEffect, useState } from 'react'
import { HiIdentification } from 'react-icons/hi2'
import { IoIosWarning } from 'react-icons/io'
import { LuGrape } from 'react-icons/lu'
import { MdBusinessCenter } from 'react-icons/md'
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar'
import { Link, Outlet } from 'react-router-dom'
import { GrapesLogo, SdcLogo } from 'src/common/assets'
import { useAuth } from 'src/common/context'
import { isSdcProject } from '../../common/helpers/isSdcProject'
import * as S from './styles'
// import WalletIcon from '@mui/icons-material/Wallet'

const appTabs = [
  {
    tab: 'Orders',
    link: '/app/orders',
    icon: <ReceiptLongIcon />
  },
  {
    tab: 'Purchase',
    link: '/app/purchase',
    icon: <RequestQuoteIcon />
  },
  {
    tab: 'Redeem',
    link: '/app/redeem',
    icon: <RequestQuoteIcon />
  },
  {
    tab: 'Create Send',
    link: '/app/create-send',
    icon: <SendIcon />
  },
  {
    tab: 'Update Send',
    link: '/app/update-send',
    icon: <ScheduleSendIcon />
  },
  {
    tab: 'User',
    link: '/app/user-details',
    icon: <AccountCircleIcon />
  },
  {
    tab: 'Addressbook',
    link: '/app/addressbook',
    icon: <RecentActorsIcon />
  },
  {
    tab: 'KYB',
    link: '/app/kyb',
    icon: <MdBusinessCenter />
  },
  {
    tab: 'KYC',
    link: '/app/kyc',
    icon: <HiIdentification />
  },
  {
    tab: 'Alerts',
    link: '/app/alert',
    icon: <CircleNotificationsIcon />
  }
]

const apiTabs = [
  {
    tab: 'Organizations',
    link: '/api/organizations',
    icon: <CorporateFareIcon />
  },
  {
    tab: 'Members',
    link: '/api/members',
    icon: <PeopleIcon />
  },
  {
    tab: 'Orders',
    link: '/api/orders',
    icon: <ReceiptLongIcon />
  },
  {
    tab: 'Purchase',
    link: '/api/purchase',
    icon: <RequestQuoteIcon />
  },
  {
    tab: 'Redeem',
    link: '/api/redeem',
    icon: <RequestQuoteIcon />
  },
  {
    tab: 'KYC',
    link: '/api/kyc',
    icon: <HiIdentification />
  },
  {
    tab: 'KYB',
    link: '/api/kyb',
    icon: <MdBusinessCenter />
  },
  {
    tab: 'Order Limits',
    link: '/api/order-limits',
    icon: <IoIosWarning />
  },
  {
    tab: 'Whitelist Payment',
    link: '/api/whitelist-payment',
    icon: <CreditScore />,
    disabled: !isSdcProject()
  },
  {
    tab: 'Blockchain Details',
    link: '/api/blockchain-details',
    icon: <CreditScore />
  },
  {
    tab: 'App Client',
    link: '/api/app-client',
    icon: <CorporateFareIcon />,
    disabled: isSdcProject()
  }
]

const toolsTab = [
  {
    tab: 'Export Orders',
    link: '/tools/export-orders',
    icon: <ReceiptLongIcon />
  },
  {
    tab: 'Features',
    link: '/tools/feature-flags',
    icon: <FlagIcon />
  },
  {
    tab: 'Fintrac',
    link: '/tools/fintrac',
    icon: <AssessmentIcon />
  }
]

interface ItemProps {
  title: string
  to: string
  selected: string
  setSelected: (title: string) => void
  icon: ReactNode
  isDisabled?: boolean
}

const Item: React.FC<ItemProps> = ({ title, to, selected, setSelected, icon, isDisabled }) => {
  return (
    <MenuItem
      active={selected === title}
      onClick={() => setSelected(title)}
      key={title}
      component={<Link to={to} />}
      icon={icon}
      disabled={isDisabled}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  )
}

const Dashboard: React.FC = () => {
  const { logout } = useAuth()

  const [selected, setSelected] = useState('Dashboard')

  useEffect(() => {
    console.log('Selected state changed:', selected)
  }, [selected])

  return (
    <S.DashboardContainer notification={false}>
      <S.SideBar>
        <Stack spacing={5}>
          <Box style={{ paddingLeft: isSdcProject() ? '50px' : 0, paddingRight: isSdcProject() ? '50px' : 0 }}>
            {isSdcProject() ? <SdcLogo height="50%" width="100%" /> : <GrapesLogo width="100%" />}
          </Box>
          <Box>
            <Sidebar>
              <Menu
                menuItemStyles={{
                  button: ({ level, active }) => {
                    if (level === 1)
                      return {
                        backgroundColor: active ? '#f4f4f4' : undefined
                      }
                  }
                }}
              >
                <MenuItem icon={<DashboardIcon />} component={<Link to="/dashboard" />}>
                  {' '}
                  Dashboard
                </MenuItem>

                <SubMenu label="App" icon={<LuGrape />} disabled={isSdcProject()}>
                  {appTabs.map((item) => (
                    <Item
                      key={item.tab}
                      title={item.tab}
                      to={item.link}
                      selected={selected}
                      setSelected={setSelected}
                      icon={item.icon}
                    ></Item>
                  ))}
                </SubMenu>

                <SubMenu label="API" icon={<ApiIcon />}>
                  {apiTabs.map((item) => (
                    <Item
                      key={item.tab}
                      title={item.tab}
                      to={item.link}
                      selected={selected}
                      setSelected={setSelected}
                      icon={item.icon}
                      isDisabled={item?.disabled}
                    ></Item>
                  ))}
                </SubMenu>
                <SubMenu label="Tools" icon={<HomeRepairServiceIcon />}>
                  {toolsTab.map((item) => (
                    <Item
                      key={item.tab}
                      title={item.tab}
                      to={item.link}
                      selected={selected}
                      setSelected={setSelected}
                      icon={item.icon}
                    ></Item>
                  ))}
                </SubMenu>
                <MenuItem icon={<LogoutIcon />}>
                  <Box onClick={logout}>Logout</Box>
                </MenuItem>
              </Menu>
            </Sidebar>
          </Box>
          ;
        </Stack>
      </S.SideBar>
      <S.RightSection>
        <Outlet />
      </S.RightSection>
    </S.DashboardContainer>
  )
}

export default Dashboard
