import { CssBaseline, ThemeProvider } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppContextProvider } from 'src/common/context'
import {
  AnalyticsDashboard,
  CreateSend,
  Dashboard,
  FeatureFlags,
  GrapesAlert,
  KYB,
  KYC,
  Login,
  LoginMfa,
  PageNotFound,
  Purchase,
  Redeem,
  UpdateSend,
  UserDetails
} from 'src/pages'
import AddressBook from 'src/pages/AddressBook/AddressBook'
import ApiKYB from 'src/pages/ApiKYB'
import ApiKYC from 'src/pages/ApiKYC'
import Members from 'src/pages/ApiMembers'
import ApiOrders from 'src/pages/ApiOrders/ApiOrders'
import Organizations from 'src/pages/ApiOrganizations/Organizations'
import { AlertDialog, AlertNotification, theme } from 'src/ui'
import { CustomRouter } from './CustomRouter'
import RequireAuth from './RequireAuth'
import ScrollToTop from './ScrollToTop'
import { history } from './history'
import { WhitelistPayment } from '../pages'
import OrderLimits from '../pages/OrderLimits'
import ExportOrders from '../pages/ExportOrders'
import Fintrac from 'src/pages/Fintrac/Fintrac'
import AppOrder from '../pages/AppOrders'
import AppClient from 'src/pages/AppClient'
import BlockchainDetails from '../pages/BlockchainDetails'

const AppRoutes: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertDialog />
      <AlertNotification />
      <AppContextProvider>
        <CustomRouter history={history}>
          <ScrollToTop />
          <Routes>
            <Route
              element={
                <Box flex="1 0 auto">
                  <Dashboard />
                </Box>
              }
            >
              <Route
                path="/mfa"
                element={
                  <RequireAuth authType="guest">
                    <LoginMfa />
                  </RequireAuth>
                }
              />
              <Route
                index
                element={
                  <RequireAuth authType="protected">
                    <Navigate to="/dashboard" replace />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <RequireAuth authType="protected">
                    <AnalyticsDashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/orders"
                element={
                  <RequireAuth authType="protected">
                    <AppOrder />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/purchase"
                element={
                  <RequireAuth authType="protected">
                    <Purchase />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/redeem"
                element={
                  <RequireAuth authType="protected">
                    <Redeem />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/create-send"
                element={
                  <RequireAuth authType="protected">
                    <CreateSend />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/update-send"
                element={
                  <RequireAuth authType="protected">
                    <UpdateSend />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/user-details"
                element={
                  <RequireAuth authType="protected">
                    <UserDetails />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/addressbook"
                element={
                  <RequireAuth authType="protected">
                    <AddressBook />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/kyb"
                element={
                  <RequireAuth authType="protected">
                    <KYB />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/kyc"
                element={
                  <RequireAuth authType="protected">
                    <KYC />
                  </RequireAuth>
                }
              />
              <Route
                path="/app/alert"
                element={
                  <RequireAuth authType="protected">
                    <GrapesAlert />
                  </RequireAuth>
                }
              />
              <Route
                path="/tools/feature-flags"
                element={
                  <RequireAuth authType="protected">
                    <FeatureFlags />
                  </RequireAuth>
                }
              />
              <Route
                path="/tools/export-orders"
                element={
                  <RequireAuth authType="protected">
                    <ExportOrders />
                  </RequireAuth>
                }
              />
              <Route
                path="/tools/fintrac"
                element={
                  <RequireAuth authType="protected">
                    <Fintrac />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/members"
                element={
                  <RequireAuth authType="protected">
                    <Members />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/kyc"
                element={
                  <RequireAuth authType="protected">
                    <ApiKYC />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/kyb"
                element={
                  <RequireAuth authType="protected">
                    <ApiKYB />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/orders"
                element={
                  <RequireAuth authType="protected">
                    <ApiOrders />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/purchase"
                element={
                  <RequireAuth authType="protected">
                    <Purchase />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/redeem"
                element={
                  <RequireAuth authType="protected">
                    <Redeem />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/whitelist-payment"
                element={
                  <RequireAuth authType="protected">
                    <WhitelistPayment />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/order-limits"
                element={
                  <RequireAuth authType="protected">
                    <OrderLimits />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/organizations"
                element={
                  <RequireAuth authType="protected">
                    <Organizations />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/blockchain-details"
                element={
                  <RequireAuth authType="protected">
                    <BlockchainDetails />
                  </RequireAuth>
                }
              />
              <Route
                path="/api/app-client"
                element={
                  <RequireAuth authType="protected">
                    <AppClient />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="/login"
              element={
                <RequireAuth authType="guest">
                  <Login />
                </RequireAuth>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </CustomRouter>
      </AppContextProvider>
    </ThemeProvider>
  )
}

export default AppRoutes
