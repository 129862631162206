import httpClient from 'src/common/axios'
import { AdminVault, OrderAnalytics, OrderDetails, OrderLimit, OrderModel } from './models'

interface CreateAdminOrderDTO {
  orderId: string
  orderStatus: string
  transferStatus: string
  transferReferenceId: string
  transactionStatus?: string
  transactionReferenceId?: string
  cryptoAmount?: number
}

interface CreateAdminSendOrderDTO {
  userId: string
  fxRate?: number
  cryptoAmount: number
  asset: string
  fiatCurrency: string
  orderStatus: string
  address: string
  chain: string
  paymentMethod: string
  contactId?: string
  bankingDetailsId?: string
}

interface UpdateAdminSendOrderDTO {
  orderId: string
  orderStatus?: string
  transferStatus?: string
  transferReferenceId?: string
  transactionStatus?: string
  transactionReferenceId?: string
  cryptoAmount?: number
  fiatAmount?: number
  asset?: string
}

interface OrderLimitDTO {
  orgId: string
  dailyBankingLimit: number
  weeklyBankingLimit: number
}

export const updateAdminPurchaseOrder = (params: CreateAdminOrderDTO) =>
  httpClient.put<OrderModel>('/orders/admin/purchase', params)

export const updateAdminRedeemOrder = (params: CreateAdminOrderDTO) =>
  httpClient.put<OrderModel>('/orders/admin/redeem', params)

export const getOrder = (id: string) => httpClient.get<OrderDetails>(`/orders/admin/order?orderId=${id}`)

export const createAdminSendOrder = (params: CreateAdminSendOrderDTO) =>
  httpClient.post<OrderModel>('/orders/admin/send', params)

export const updateAdminSendOrder = (params: UpdateAdminSendOrderDTO) =>
  httpClient.put<OrderModel>('/orders/admin/send', params)

export const fetchOrderAnalytics = () => httpClient.get<OrderAnalytics[]>('/orders/admin/analytics')

export const getOrganizationOrderLimits = (orgId: string) =>
  httpClient.get<OrderLimit>(`/orders/order-limit?orgId=${orgId}`)

export const updateOrganizationOrderLimit = (params: OrderLimitDTO) =>
  httpClient.put<OrderLimit>('/orders/order-limit', params)

export const fetchAdminVaultBalances = () => httpClient.get<AdminVault>('/orders/admin-vault-balances')
