import httpClient from 'src/common/axios/config'
import { CreateOrgCredentialsDTO, DeleteOrgCredentialsDTO, GetOrgCredentialsDTO } from './dto'
import { ICredentialsModel } from './models'

export const adminCreateOrgCredentials = (params: CreateOrgCredentialsDTO) =>
  httpClient.post<ICredentialsModel>('/organizations/credentials', params)

export const adminGetOrgCredentials = (params: GetOrgCredentialsDTO) =>
  httpClient.get<ICredentialsModel>(`/organizations/credentials?orgId=${params.orgId}`)

export const adminDeleteOrgCredentials = (params: DeleteOrgCredentialsDTO) => {
  return httpClient.delete('/organizations/credentials', {
    data: params
  })
}
