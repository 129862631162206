import { CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { AdminVault, fetchAdminVaultBalances, SupportedAsset } from 'src/common/api'
import { alertNotification } from '../../../ui'
import { AlgoIcon, EthIcon, QCADIcon, StellarIcon, USDCIcon } from '../../../common/assets'
import { getAssetName } from '../../../common/helpers'
import HelpIcon from '@mui/icons-material/Help'
import { isSdcProject } from '../../../common/helpers/isSdcProject'

interface FireblocksHoldingsOverviewProps {
  firstName?: string
}

const FireblocksHoldingsOverview: React.FC<FireblocksHoldingsOverviewProps> = ({
  firstName
}: FireblocksHoldingsOverviewProps) => {
  const [adminVault, setAdminVault] = useState<AdminVault | null>(null)
  const [loading, setLoading] = useState(true)

  const getAdminVaultBalances = async () => {
    try {
      const response = await fetchAdminVaultBalances()
      setAdminVault(response.data)
    } catch (error) {
      console.error('Error fetching admin vault balances:', error)
      alertNotification('Error fetching orders')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAdminVaultBalances()
  }, [])

  const sortedAssets = useMemo(() => {
    if (adminVault?.assets) {
      return adminVault.assets.sort((a, b) => {
        const nameA = getAssetName(a.id).toLowerCase()
        const nameB = getAssetName(b.id).toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    }
    return []
  }, [adminVault?.assets])

  const cadUsdcAssets: SupportedAsset[] = []
  const otherAssets: SupportedAsset[] = []

  sortedAssets.forEach((asset) => {
    const assetName = getAssetName(asset.id).toLowerCase()
    if (assetName.includes('usdc') || assetName.includes('cad')) {
      cadUsdcAssets.push(asset)
    } else {
      otherAssets.push(asset)
    }
  })

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Stack justifyContent="space-around" alignSelf="stretch" flex="1 1 50%">
        <Stack spacing={2}>
          <Typography variant="h5" fontWeight={500}>
            {isSdcProject() ? 'Welcome SDC Admin~' : `Welcome back, ${firstName}!`}
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : adminVault?.assets ? (
            <Stack key={adminVault.name} spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h5" fontWeight={400}>
                  {adminVault.name}
                </Typography>
              </Stack>
              <Typography variant="h6" fontWeight={500}>
                n-CAD and USDC Assets
              </Typography>
              <Grid container spacing={2}>
                {cadUsdcAssets.map((asset) => (
                  <Grid item xs={12} sm={6} key={asset.id}>
                    <Stack>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {(() => {
                          const assetName = getAssetName(asset.id).toLowerCase()
                          const iconMapping: { [key: string]: React.ReactNode } = {
                            usdc: <USDCIcon fontSize="large" />,
                            cad: <QCADIcon fontSize="large" />
                          }
                          const iconComponent = Object.keys(iconMapping).find((key) => assetName.includes(key))
                          return iconComponent ? iconMapping[iconComponent] : null
                        })()}
                        <Typography variant="h6" fontWeight={500}>
                          {getAssetName(asset.id)}
                        </Typography>
                      </Stack>
                      <Stack display="flex" flexDirection="column">
                        <Typography marginLeft="50px" variant="subtitle1">
                          {'Balance: ' + asset.balance}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              <Typography variant="h6" fontWeight={500} marginTop={4}>
                Other Assets
              </Typography>
              <Grid container spacing={2}>
                {otherAssets.map((asset) => (
                  <Grid item xs={12} sm={6} key={asset.id}>
                    <Stack>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {(() => {
                          const assetName = getAssetName(asset.id).toLowerCase()
                          const iconMapping: { [key: string]: React.ReactNode } = {
                            algo: <AlgoIcon fontSize="large" />,
                            ethereum: <EthIcon fontSize="large" />,
                            stellar: <StellarIcon fontSize="large" />
                          }
                          const iconComponent = Object.keys(iconMapping).find((key) => assetName.includes(key))
                          return iconComponent ? iconMapping[iconComponent] : <HelpIcon fontSize="large" />
                        })()}
                        <Typography variant="h6" fontWeight={500}>
                          {getAssetName(asset.id)}
                        </Typography>
                      </Stack>
                      <Stack display="flex" flexDirection="column">
                        <Typography marginLeft="50px" variant="subtitle1">
                          {'Balance: ' + asset.balance}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          ) : (
            <Typography variant="body1">No data available</Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default FireblocksHoldingsOverview
