import { LoadingButton } from '@mui/lab'
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import {
  adminCreateOrgCredentials,
  adminDeleteOrgCredentials,
  adminGetOrgCredentials
} from 'src/common/api/appClient/data'
import { CreateOrgCredentialsDTO, DeleteOrgCredentialsDTO, GetOrgCredentialsDTO } from 'src/common/api/appClient/dto'
import { ICredentialsModel } from 'src/common/api/appClient/models'
import { Container, alertNotification } from 'src/ui'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { AxiosError } from 'axios'

interface ActionFormState {
  action: 'create-credentials' | 'get-credentials' | 'delete-credentials' | ''
  create?: {
    orgId?: string
  }
  get?: {
    orgId?: string
  }
  delete?: {
    orgId?: string
    clientId?: string
  }
}

const AppClient: React.FC = () => {
  const [actionFormState, setActionFormState] = useState<ActionFormState>({
    action: '',
    create: {
      orgId: ''
    },
    get: {
      orgId: ''
    },
    delete: {
      orgId: ''
    }
  })
  const [submitting, setSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [createResponse, setCreateResponse] = useState<ICredentialsModel>({ clientId: '', clientSecret: '' })
  const [getResponse, setGetResponse] = useState<ICredentialsModel[]>([{ clientId: '', clientSecret: '' }])
  const { action } = actionFormState

  const handleExecute = async () => {
    try {
      setSubmitting(true)
      let res
      let successMessage
      if (action === 'create-credentials') {
        const params: CreateOrgCredentialsDTO = {
          orgId: actionFormState.create?.orgId
        }
        res = await adminCreateOrgCredentials(params)
        successMessage = 'Credentials Created!'
        setCreateResponse({ clientId: res.data.clientId, clientSecret: res.data.clientSecret })
      } else if (action === 'get-credentials') {
        const params: GetOrgCredentialsDTO = {
          orgId: actionFormState.get?.orgId
        }
        res = await adminGetOrgCredentials(params)
        successMessage = 'Credentials Retrieved!'
        const castedData = res.data as unknown as ICredentialsModel[]
        setGetResponse(castedData)
      } else {
        const params: DeleteOrgCredentialsDTO = {
          orgId: actionFormState.delete?.orgId,
          clientId: actionFormState.delete?.clientId
        }
        res = await adminDeleteOrgCredentials(params)
        successMessage = 'Credentials Deleted!'
      }
      setSubmitting(false)
      setSuccessMessage(successMessage)
    } catch (error) {
      setSubmitting(false)
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.message || 'Something went wrong, please try again later.'
        alertNotification(errorMessage, 'error')
      } else if (error instanceof Error && error.message.includes('404')) {
        alertNotification('Order not found.', 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setActionFormState({
      action: value as '' | 'create-credentials' | 'get-credentials' | 'delete-credentials',
      create: {
        orgId: ''
      },
      get: {
        orgId: ''
      },
      delete: {
        orgId: '',
        clientId: ''
      }
    })
    setCreateResponse({ clientId: '', clientSecret: '' })
    setGetResponse([{ clientId: '', clientSecret: '' }])
  }

  const copytoClipboard = (text: string | undefined) => {
    if (text) {
      navigator.clipboard.writeText(text)
    }
  }

  const handleParamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setActionFormState((prevState) => {
      if (prevState.action === 'create-credentials') {
        return {
          ...prevState,
          create: {
            ...prevState.create,
            orgId: value
          }
        }
      } else if (prevState.action === 'get-credentials') {
        return {
          ...prevState,
          get: {
            ...prevState.get,
            orgId: value
          }
        }
      } else if (prevState.action === 'delete-credentials') {
        if (name === 'orgId') {
          return {
            ...prevState,
            delete: {
              ...prevState.delete,
              orgId: value,
              clientId: prevState.delete?.clientId
            }
          }
        } else {
          return {
            ...prevState,
            delete: {
              ...prevState.delete,
              orgId: prevState.delete?.orgId,
              clientId: value
            }
          }
        }
      } else {
        return {
          ...prevState,
          create: name === 'action' && value === 'create-credentials' ? { orgId: '' } : prevState.create,
          get: name === 'action' && value === 'get-credentials' ? { orgId: '' } : prevState.get,
          delete: name === 'action' && value === 'delete-credentials' ? { orgId: '', clientId: '' } : prevState.delete
        }
      }
    })
  }

  return (
    <Box>
      <Container success={successMessage} maxWidth={700}>
        <Stack spacing={3}>
          <>
            <Typography variant="h6">Organization App Client</Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {'Select Action'}
              <TextField
                name="action"
                select
                placeholder="Select Flag"
                onChange={handleActionChange}
                value={action}
                fullWidth
              >
                <MenuItem value="create-credentials">Create Credentials</MenuItem>
                <MenuItem value="get-credentials">Get Credentials</MenuItem>
                <MenuItem value="delete-credentials">Delete Credentials</MenuItem>
              </TextField>
            </Stack>
            {actionFormState.action === 'create-credentials' && (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                {'Org Id'}
                <TextField
                  name="orgId"
                  placeholder="Id"
                  onChange={handleParamsChange}
                  value={actionFormState.create?.orgId}
                  fullWidth
                  multiline
                  sx={{ width: '87%' }}
                />
              </Stack>
            )}
            {actionFormState.action === 'get-credentials' && (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                {'Org Id'}
                <TextField
                  name="orgId"
                  placeholder="Id"
                  onChange={handleParamsChange}
                  value={actionFormState.get?.orgId}
                  fullWidth
                  multiline
                  sx={{ width: '87%' }}
                />
              </Stack>
            )}
            {actionFormState.action === 'delete-credentials' && (
              <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {'Org Id'}
                  <TextField
                    name="orgId"
                    placeholder="Id"
                    onChange={handleParamsChange}
                    value={actionFormState.delete?.orgId}
                    fullWidth
                    multiline
                    sx={{ width: '87%' }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '1.5rem' }}>
                  {'Client Id'}
                  <TextField
                    name="clientId"
                    placeholder="Id"
                    onChange={handleParamsChange}
                    value={actionFormState.delete?.clientId}
                    fullWidth
                    multiline
                    sx={{ width: '87%' }}
                  />{' '}
                </Stack>
              </Box>
            )}
            <LoadingButton variant="contained" fullWidth size="large" loading={submitting} onClick={handleExecute}>
              Execute
            </LoadingButton>
          </>
        </Stack>
      </Container>
      {createResponse.clientId !== '' && (
        <Container maxWidth={700}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box>{`Client Id:`}</Box>
            <Box fontWeight={500}>{`${createResponse.clientId}`}</Box>
            <ContentCopyIcon onClick={() => copytoClipboard(createResponse.clientId)} style={{ cursor: 'pointer' }} />
          </Stack>
          {createResponse.clientSecret && (
            <Stack direction="row" spacing={2} alignItems="center">
              <Box>{`Client Secret:`}</Box>
              <Box fontWeight={500}>{`${createResponse.clientSecret}`}</Box>
              <ContentCopyIcon
                onClick={() => copytoClipboard(createResponse.clientSecret)}
                style={{ cursor: 'pointer' }}
              />
            </Stack>
          )}
        </Container>
      )}
      {getResponse[0].clientId !== '' &&
        getResponse.map((response, index) => (
          <Container key={index} maxWidth={700}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box>{`Client Id:`}</Box>
              <Box fontWeight={500}>{`${response.clientId}`}</Box>
              <ContentCopyIcon onClick={() => copytoClipboard(response.clientId)} style={{ cursor: 'pointer' }} />
            </Stack>
            {response.clientSecret && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Box>{`Client Secret:`}</Box>
                <Box fontWeight={500}>{`${response.clientSecret}`}</Box>
                <ContentCopyIcon onClick={() => copytoClipboard(response.clientSecret)} style={{ cursor: 'pointer' }} />
              </Stack>
            )}
          </Container>
        ))}
    </Box>
  )
}

export default AppClient
